import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import "../../fragments"
import Layout from "../../components/layout"
import Features from "../../components/features/features"
import News from "../../components/news"

export default function DesignPage({ data }) {

  return (
    <Layout className="page-design">
      <Container className="mt-5">
        <Row>
            <Col className="mb-5 pe-5">
              <h2 className="striped-header mb-4"><span>Updates</span></h2>
              <News items={data?.updates?.nodes} columnsCountBreakPoints={{600: 2, 900: 3}}/>
            </Col>
        </Row>
        <Row>
          <h2 className="striped-header mb-5"><span>Selected projects</span></h2>
          <Features projects={data?.projects?.nodes} />
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query DesignQuery {
    updates: allNews(
      sort: {fields: date, order: DESC}, 
      limit: 6
      filter: {tags: {in: ["design"]}}
    ) {
      nodes {
        date
        message
        tags
      }
    }
    projects: allDesign(
      filter: {
        lang: {eq: "en"}, 
        status: {eq: published}
        featured: {eq: true}
      },
      sort: {fields: date, order: DESC},
      limit: 50
    ) {
      nodes {
        ...designProperties
      }
    }
  }
`